var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"main"},[_c('div',{staticClass:"grid"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center py-5 pt-sm-5 pt-md-8 pt-lg-8 mr-5 ml-5 mb-0 pb-0"},[_c('span',{staticClass:"section-title font-size-18"},[_vm._v("BLOGS")]),_c('h3',{staticClass:"section-subtitle pt-2 section3-title"},[_vm._v(" Grow Your Company With Our Resources ")]),_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('b-spinner'):_vm._e()],1),(_vm.userId != null)?_c('h4',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"/addblog"}},[_vm._v("Write to us")])]):_vm._e()])])],1),_c('b-row',{staticClass:"mt-0 ml-lg-13 mr-lg-14 ml-5 mr-5"},[(_vm.blog.length > 0)?_c('b-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('b-row',_vm._l((_vm.blog.slice(0, _vm.blogToShow)),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"12","sm":"6"}},[(index + 1 <= _vm.blogToShow)?_c('div',[_c('div',{staticClass:"image-with-text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToBlogDetail(item['id'])}}},[_c('b-img',{staticClass:"w-100 blog-img",attrs:{"src":item['banner_image'],"alt":"Circle image","height":"350"}}),(item['description'])?_c('div',{staticClass:"pt-3 pb-0 p-1"},[_c('p',{staticClass:"letter-spacing-0 text-justify p-3",domProps:{"innerHTML":_vm._s(
                      item['description']
                        .replace(/(<([^>]+)>)/gi, '')
                        .substring(0, 150)
                    )}})]):_vm._e()],1)]):_vm._e()])}),1),_c('b-col',{staticClass:"text-center pb-10"},[(_vm.blogToShow < _vm.reverse_blog.length)?_c('v-btn',{attrs:{"depressed":"","elevation":"6","icon":"","outlined":"","raised":"","color":"black"},on:{"click":function($event){_vm.blogToShow += 4}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1):_vm._e(),_c('b-col',{staticClass:"pt-6",attrs:{"cols":"12","sm":"12","lg":"4"}},[(_vm.reverse_blog.length > 0)?_c('b-card',{staticClass:"box-shadow"},[_c('span',[_vm._v("Recent Post")]),_vm._l((_vm.reverse_blog),function(item,index){return _c('div',{key:index},[(index <= 2)?_c('b-row',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToBlogDetail(item['id'])}}},[_c('b-col',{staticClass:"pr-0 text-center text-lg-left",attrs:{"cols":"12","sm":"4"}},[_c('b-img',{staticClass:"w-75 h-100 rounded",attrs:{"src":item['banner_image'],"alt":"Circle image"}})],1),(item['description'])?_c('b-col',{staticClass:"pl-lg-0",attrs:{"cols":"12","sm":"8"}},[(
                    item['description'].replace(/(<([^>]+)>)/gi, '').length <
                    80
                  )?_c('p',{staticClass:"font-size-12 text-muted",domProps:{"innerHTML":_vm._s(item['description'])}}):_c('p',{staticClass:"font-size-12 text-muted",domProps:{"innerHTML":_vm._s(
                    item['description']
                      .replace(/(<([^>]+)>)/gi, '')
                      .substring(0, 80) + '...'
                  )}}),_c('small',{staticClass:"text-capitalize font-size-12 text-left"},[_c('span',{staticClass:"text-muted text-right"},[_vm._v(_vm._s(_vm._f("formatDate")(item["postdate"])))])])]):_vm._e(),_c('hr')],1):_vm._e()],1)})],2):_vm._e(),_c('b-card',{staticClass:"mt-5 box-shadow mb-15 mb-lg-0"},[_c('span',[_vm._v("All Categories")]),_vm._l((_vm.parent_cat),function(items,index){return _c('b-row',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToFindDetail()}}},[_c('b-col',{attrs:{"cols":"12"}},[_c('small',{staticClass:"text-capitalize text-muted text-left font-size-12"},[_vm._v(" "+_vm._s(items["name"])),_c('span',{staticClass:"text-muted",staticStyle:{"float":"right"}},[_vm._v("("+_vm._s(items["total_count"])+")")])])]),_c('hr')],1)})],2)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }