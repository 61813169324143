<template>
  <div >
    <section class="main">
      <div class="grid">
        <b-col cols="12">
          <div class="text-center py-5 pt-sm-5 pt-md-8 pt-lg-8 mr-5 ml-5 mb-0 pb-0">
            <span class="section-title font-size-18">BLOGS</span>
            <h3 class="section-subtitle pt-2 section3-title">
              Grow Your Company With Our Resources
            </h3>
            <div class="text-center">
              <b-spinner v-if="loading"></b-spinner>
            </div>
            <h4 v-if="userId != null">
              <a class="text-decoration-none" href="/addblog">Write to us</a>
            </h4>
          </div>
        </b-col>
      </div>
      <b-row class="mt-0 ml-lg-13 mr-lg-14 ml-5 mr-5">
        <b-col cols="12" sm="12" lg="8" v-if="blog.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              v-for="(item, index) in blog.slice(0, blogToShow)"
              :key="index"
            >
              <div v-if="index + 1 <= blogToShow">
                <div
                  class="image-with-text"
                  @click="goToBlogDetail(item['id'])"
                  style="cursor: pointer"
                >
                  <b-img
                    :src="item['banner_image']"
                    alt="Circle image"
                    class="w-100 blog-img"
                    height="350"
                  ></b-img>
                  <div class="pt-3 pb-0 p-1" v-if="item['description']">
                    <p
                      class="letter-spacing-0 text-justify p-3"
                      v-html="
                        item['description']
                          .replace(/(<([^>]+)>)/gi, '')
                          .substring(0, 150)
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-col class="text-center pb-10">
            <!-- <button
              class="txt-brown font-bold mt-5"
              v-if="blogToShow < reverse_blog.length"
              @click="blogToShow += 4"
            > -->
            <v-btn
              depressed
              elevation="6"
              icon
              outlined
              raised
              v-if="blogToShow < reverse_blog.length"
              @click="blogToShow += 4"
              color="black"
            >
              <v-icon>mdi-plus</v-icon></v-btn
            >
            <!-- </button> -->
          </b-col>
        </b-col>

        <b-col cols="12" sm="12" lg="4" class="pt-6">
          <b-card class="box-shadow" v-if="reverse_blog.length > 0">
            <span>Recent Post</span>

            <div v-for="(item, index) in reverse_blog" :key="index">
              <b-row
                v-if="index <= 2"
                @click="goToBlogDetail(item['id'])"
                style="cursor: pointer"
              >
                <b-col cols="12" sm="4" class="pr-0 text-center text-lg-left">
                  <b-img
                    :src="item['banner_image']"
                    alt="Circle image"
                    class="w-75 h-100 rounded"
                  ></b-img>
                </b-col>
                <b-col
                  cols="12"
                  sm="8"
                  class="pl-lg-0"
                  v-if="item['description']"
                >
                  <p
                    class="font-size-12 text-muted"
                    v-if="
                      item['description'].replace(/(<([^>]+)>)/gi, '').length <
                      80
                    "
                    v-html="item['description']"
                  ></p>
                  <p
                    class="font-size-12 text-muted"
                    v-else
                    v-html="
                      item['description']
                        .replace(/(<([^>]+)>)/gi, '')
                        .substring(0, 80) + '...'
                    "
                  ></p>
                  <small class="text-capitalize font-size-12 text-left">
                    <span class="text-muted text-right">{{
                      item["postdate"] | formatDate
                    }}</span>
                  </small>
                </b-col>
                <hr />
              </b-row>
            </div>
          </b-card>
          <b-card class="mt-5 box-shadow mb-15 mb-lg-0">
            <span>All Categories</span>
            <b-row
              v-for="(items, index) in parent_cat"
              :key="index"
              @click="goToFindDetail()"
              style="cursor: pointer"
            >
              <b-col cols="12" class="">
                <small
                  class="text-capitalize text-muted text-left font-size-12"
                >
                  {{ items["name"]
                  }}<span style="float: right" class="text-muted"
                    >({{ items["total_count"] }})</span
                  >
                </small>
              </b-col>
              <hr />
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row class="hideChart">
        <b-col class="text-center pb-10">
          <button
            class="button-bg-dark border-radius-8 p-2 text-white"
            v-if="blogToShow < reverse_blog.length"
            @click="blogToShow += 4"
          >
            Load More
          </button>
        </b-col>
      </b-row> -->
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "blog",
  data() {
    return {
      blogToShow: 4,
      blog: [],
      reverse_blog: [],
      parent_cat: [],
      userId: null,
      loading:true,
    };
  },
  created() {
    this.getblogdetail();
    this.getcategory();
  },
  methods: {
    ...mapActions(["getBlog", "getCompanyCategory"]),

    getblogdetail() {
      this.getBlog().then((response) => {
        this.loading = true;
        console.log(response.data.data);
        response.data.data.forEach((value) => {
          this.blog.push(value);
        });
        this.reverse_blog = [].concat(this.blog).reverse();
      })
      .finally(() => (this.loading = false));
    },
    getcategory() {
      this.getCompanyCategory().then((response) => {
         this.loading = true;
        response.data.data.forEach((value) => {
          if (value["parent_id"] == 0) {
            let cat = {};
            let name = value["name"];
            let id = value["id"];
            let count = 0;
            response.data.data.forEach((v) => {
              if (v["parent_id"] == id) {
                count++;
              }
            });
            cat["id"] = id;
            cat["name"] = name;
            cat["total_count"] = count;
            this.parent_cat.push(cat);
          }
        });
      })
      .finally(() => (this.loading = false));
    },
    goToBlogDetail(id) {
      this.$router.push({ name: "blogdetail", params: { id: id } });
    },
    goToFindDetail() {
      this.$router.push("/findcompany");
    },
  },
};
</script>
<style scoped>

</style>
